body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input {
  border: 1px solid #999;
  padding: 8px;
  width: 300px;
}
.no-autocomplete {
  color: #999;
  padding: 8px;
}

.bodersignature 
{
  border: 1px solid #999;
}

.autocomplete {
  border: 1px solid #999;
  border-top-width: 1;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  
}
.autocomplete li {
  padding: 8px;
}
.autocomplete > .active,
.autocomplete li:hover {
  background-color: rgb(48, 48, 48);
  cursor: pointer;
  font-weight: 700;
}
.autocomplete li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}